<template>
  <v-container>
    <DashboardHeader icon="mdi-arrow-left" title="ගිණුම් විස්තර" />
    <Profile :myProfile="false" />
  </v-container>
</template>

<script>
import DashboardHeader from "../components/DashboardHeader";
import Profile from "../components/Profile";

export default {
  name: "profile",
  components: {
    DashboardHeader,
    Profile
  }
};
</script>

<style>
.tbl-cont tbody tr td {
  font-size: 20px;
  padding: 5px;
  /* font-weight: 100 */
}
.tbl-cont2 tbody tr {
  border-bottom: 1px solid black;
  /* font-weight: 100 */
}
.tbl-cont2 tbody tr td {
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 10px;

  /* font-weight: 100 */
}
</style>